<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.egress_profile_type.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Minutos por Hora Pedagógica"
      label-for="input-module-minutes"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-module-minutes"
        name="input-module-minutes"
        v-model="$v.egress_profile_type.module_minutes.$model"
        :state="validateState('module_minutes')"
        aria-describedby="input-minute-modules-live-feedback"
        size="md"
        type="number"
      ></b-form-input>
      <b-form-invalid-feedback id="input-minute-modules-live-feedback"
        >Este campo no debe ser menor a 0.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="!isNaN(this.egress_profile_type.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="egress_profile_type"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.egress_profile_type.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteEgressProfileType"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength, minValue } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "EgressProfileTypeForm",
  mixins: [validationMixin],
  components: {
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    default_module_minutes: {
      type: [Number, null],
      default: 0,
    },
    EgressProfileType: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          module_minutes: this.default_module_minutes,
          updated_by: null,
          update_date: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      egress_profile_type: { ...this.EgressProfileType },
    };
  },
  validations: {
    egress_profile_type: {
      name: {
        required,
        minLength: minLength(5),
      },
      module_minutes: {
        required,
        minValue: minValue(1),
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.egress_profile_type[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.egress_profile_type.$touch();
      if (this.$v.egress_profile_type.$anyError) {
        return;
      }
      if (isNaN(this.egress_profile_type.id)) {
        this.createEgressProfileType();
      } else this.updateEgressProfileType();
    },
    createEgressProfileType() {
      this.$store
        .dispatch(names.POST_PROFILE_TYPE, this.egress_profile_type)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.egressprofiletype",
                false,
                "Tipo de Perfil de Egreso"
              ) + " creado."
            )
          );
          this.$emit("created", response);
        });
    },
    updateEgressProfileType() {
      this.$store
        .dispatch(names.UPDATE_PROFILE_TYPE, this.egress_profile_type)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.egressprofiletype",
                false,
                "Tipo de Perfil de Egreso"
              ) + " actualizado."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteEgressProfileType() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.egressprofiletype",
          false,
          "Tipo de Perfil de Egreso"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_PROFILE_TYPE,
            this.egress_profile_type.id
          );
          this.$emit("deleted", this.egress_profile_type);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>